import React, { useEffect, useState } from 'react';
import { Alert, Box, Container, Fade } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIdobaAuthV2 } from '@optika-solutions/shared-react';
import LoginFooter from 'components/login/LoginFooter/LoginFooter';
import LoginForm from 'components/login/LoginForm';
import RequestPasswordResetForm from 'components/login/RequestPasswordResetForm';
import ResetConfirmed from 'components/login/ResetConfirmed';
import IdobaLogo from '../../components/icons/IdobaLogin';
import { alertObjectType, loginErrorTypes, loginFormsTypes } from 'utils/types';

//const Cookies = require('js-cookie');

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasUsernameError, setHasUsernameError] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [displayView, setDisplayView] = useState<loginFormsTypes>(loginFormsTypes.login);
  const returnUrl = params.get('return_url');
  const [globalMessage, setGlobalMessage] = useState('');
  const { authService } = useIdobaAuthV2();

  const logoutText = 'You have been successfully logged out of DiiMOS';
  const [alertObject, setAlertObject] = useState<alertObjectType | undefined>(undefined);
  const [showAlert, setShowAlert] = useState(false);

  // Used for testing view states
  useEffect(() => {
    const view = params.get('view') as loginFormsTypes | null;

    if (view && [loginFormsTypes.forgetPasswordCode].includes(view)) {
      setDisplayView(view);
    }
  }, [setDisplayView, params]);

  useEffect(() => {
    const checkAuth = async () => {
      if (await authService.isAuthenticated) {
        if (returnUrl) {
          window.location.replace(returnUrl);
          return;
        } else {
          navigate('/modules');
        }
      }
    };

    checkAuth();
  }, [setGlobalMessage, navigate, returnUrl, authService]);

  useEffect(() => {
    if (alertObject) {
      setShowAlert(true);
    }
    if (alertObject?.timeout) {
      const alertTimer = setTimeout(() => {
        setShowAlert(false);
      }, alertObject.timeout);

      return () => clearTimeout(alertTimer);
    }
  }, [alertObject]);

  const clearLoginScreen = (view: loginFormsTypes) => {
    //if (view !== loginFormsTypes.resetPassword) {
    setUsername('');
    //}
    //if (![loginFormsTypes.resetPassword].includes(view)) {
    setPassword('');
    //}
    setErrors('');
    setHasUsernameError(false);
    setHasPasswordError(false);
    setLoading(false);
  };

  useEffect(() => {
    clearLoginScreen(displayView);
  }, [displayView]);

  useEffect(() => {
    //if password error is set, and the user has changed the password at all, clear the error until triggered again
    if (hasPasswordError) {
      setHasPasswordError(false);
    }
    if (errors) {
      setErrors('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, hasPasswordError]);

  const forgetPassword = () => {
    setDisplayView(loginFormsTypes.forgetPasswordCode);
  };

  const backNavigation = () => {
    setDisplayView(loginFormsTypes.login);
  };

  const resetErrors = (errorType: loginErrorTypes) => {
    setErrors('');
    switch (errorType) {
      case loginErrorTypes.username:
        setHasUsernameError(false);
        break;
      case loginErrorTypes.password:
        setHasPasswordError(false);
        break;
      default:
        break;
    }
  };

  const login = () => {
    setLoading(true);
    setErrors('');
    setHasUsernameError(false);
    setHasPasswordError(false);

    authService
      .login(username, password)
      .then((result) => {
        if (result.data) {
          if (returnUrl) {
            window.location.replace(returnUrl);
          } else {
            navigate('/modules');
          }
        }
        setLoading(false);
        if (result.status && [400, 401].includes(result.status)) {
          setErrors('Email address and password combination is incorrect. Please try again.');
        } else if (result.status && [429].includes(result.status)) {
          setErrors(
            'You have made too many invalid attempts. For your security please reset your password via the Forgot password link below'
          );
        } else {
          setErrors(result.message);
        }
      })
      .catch((error: Error) => {
        setLoading(false);
        setErrors(error.message);
      });
  };

  const requestReset = () => {
    // Alert user that if their email is stored, they should be getting confirmation code.
    //setAlertObject({ type: 'success', message: emailSentForValidationText });

    if (!username) {
      setErrors('Email is a required field');
      setHasUsernameError(true);
      return;
    }
    setLoading(true);
    resetErrors(loginErrorTypes.username);

    authService
      .requestPasswordReset(username)
      .then((result) => {
        if (result) {
          if (!result.success) {
            //setHasUsernameError(true);
            setAlertObject({
              type: 'error',
              message: 'An error occured requesting a password reset',
              timeout: 5000,
            });
            setLoading(false);

            return;
          }
        }
        setDisplayView(loginFormsTypes.resetConfirmed);
        setLoading(false);
      })
      .catch((error: Error) => {
        setLoading(false);
        console.log('Login has returned undefined error of: ', error);
      });
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      {globalMessage === 'logout' && (
        <Alert sx={{ mt: 5, width: '441px', left: '532px' }} severity="info">
          {logoutText}
        </Alert>
      )}

      <Container sx={{ mt: 22 }} maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 126,
            borderRadius: '4px',
            mb: '32px',
          }}
        >
          <IdobaLogo />
        </Box>

        {displayView === loginFormsTypes.login && (
          <>
            <LoginForm
              username={username}
              password={password}
              setUsername={setUsername}
              setPassword={setPassword}
              isLoading={loading}
              hasUsernameError={hasUsernameError}
              hasPasswordError={hasPasswordError}
              errors={errors}
              onLogin={login}
              forgetPasswordHandler={forgetPassword}
              setAlert={setAlertObject}
            />
          </>
        )}

        {displayView === loginFormsTypes.forgetPasswordCode && (
          <RequestPasswordResetForm
            username={username}
            setUsername={setUsername}
            requestReset={requestReset}
            backNavigation={backNavigation}
            hasUsernameError={hasUsernameError}
            errors={errors}
            hasReadError={resetErrors}
            isLoading={loading}
          />
        )}

        {displayView === loginFormsTypes.resetConfirmed && <ResetConfirmed />}

        <LoginFooter />
      </Container>
      <Fade
        in={showAlert}
        easing={{ exit: 'ease-out' }}
        timeout={{ enter: 0, exit: 1000 }}
        onExited={() => setAlertObject(undefined)}
      >
        <Alert
          sx={{
            position: 'absolute',
            top: '56px',
            zIndex: 20,
          }}
          iconMapping={{
            success: <CheckCircleOutline fontSize="inherit" />,
          }}
          onClose={!alertObject?.timeout ? () => setShowAlert(false) : undefined}
          severity={alertObject?.type}
        >
          {alertObject?.message}
        </Alert>
      </Fade>
    </Box>
  );
};

export default Login;
