import React from 'react';
import { Fade, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from '@mui/material/styles';

const ResetConfirmed: React.FC<{}> = () => {
  const muiTheme = useTheme();
  return (
    <React.Fragment>
      <Fade in appear>
        <Box
          sx={{
            width: '100%',
            maxWidth: 500,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <CheckCircleOutlineIcon
            style={{ color: muiTheme.palette.success.light }}
            sx={{ fontSize: 35 }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '10px',
            }}
          >
            <Typography variant="subtitle1">Password reset requested.</Typography>
            <Typography variant="body2" color="text.secondary">
              Please check your email for a password reset link.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              This page is now safe close.
            </Typography>
          </Box>
        </Box>
      </Fade>
    </React.Fragment>
  );
};

export default ResetConfirmed;
